import React from 'react'

function PoyaDayElement({ poyaday, deletePoyaDay }) {
  return (
    <div className="PoyaDayElement rounded bg-dark m-1 align-middle row">
      <li className='list col-6 text-light col-10 fs-5 '>{poyaday.Date} පොහෝ දින {poyaday.Doner} විසින් {poyaday.EventName} පිංකම</li>
      <button onClick={() => deletePoyaDay(poyaday.id)} className='btn btn-danger col-2 '> <i className="fa-solid fa-trash"></i></button>

    </div>
  )
}

export default PoyaDayElement; 