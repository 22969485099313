import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './AboutUs';
import Events from './Events';
import ImageGallery from './ImageGallery';
import NoticeBoard from './NoticeBoard';
import ContactUs from './ContactUs';
import Home from './Home';
import Loginpg from './Loginpg';
import AdminPage from './AdminPage';
import ProtectedRoute from './ProtectedRoute';
import { AuthContextProvider } from './AuthContext';

function App() {
  return (

    <div className="App">
      <Router>
        <div className="nav-links">
          <AuthContextProvider>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<AboutUs />} />
              <Route path='/events' element={<Events />} />
              <Route path='/gallery' element={<ImageGallery />} />
              <Route path='/notice' element={<NoticeBoard />} />
              <Route path='/contactus' element={<ContactUs />} />
              <Route path='/login' element={<Loginpg />} />
              <Route path='/admin' element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
            </Routes>
          </AuthContextProvider>
        </div>
      </Router>
    </div>

  );
}

export default App;
