import React, { useState, useEffect } from 'react'
import { db } from './firebase';
import { query, onSnapshot, collection } from 'firebase/firestore';
import './App.css'
import { Link } from 'react-router-dom';
function Events() {
  const [monthData, setMonthData] = useState('');
  const [danaLists, setDanaLists] = React.useState({
    DawalDanaList: [],
    HeelDanaList: []
  });

  useEffect(() => {
    const dawalDanaQuery = query(collection(db, "DawalDanaya"));
    const heelDanaQuery = query(collection(db, "HeelDanaya"));

    const dawalUnsubscribe = onSnapshot(dawalDanaQuery, (querySnapshot) => {
      const dawalDanaArr = [];
      querySnapshot.forEach((doc) => {
        dawalDanaArr.push({ ...doc.data(), id: doc.id });
      });
      dawalDanaArr.sort((a, b) => parseInt(a.Date) - parseInt(b.Date));
      // Sort DawalDanaList by Doner
      setDanaLists(prevState => ({ ...prevState, DawalDanaList: dawalDanaArr }));
    });

    const heelUnsubscribe = onSnapshot(heelDanaQuery, (querySnapshot) => {
      const heelDanaArr = [];
      querySnapshot.forEach((doc) => {
        heelDanaArr.push({ ...doc.data(), id: doc.id });
      });
      heelDanaArr.sort((a, b) => parseInt(a.Date) - parseInt(b.Date)); // Sort HeelDanaList by Doner
      setDanaLists(prevState => ({ ...prevState, HeelDanaList: heelDanaArr }));
    });

    return () => {
      dawalUnsubscribe();
      heelUnsubscribe();
    };
  }, []);


  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'Month'), (snapshot) => {
      snapshot.forEach((doc) => {

        setMonthData(doc.data().MonthField);
      });
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="events container-fluid bg-dark">
      <Link to="/" className=''>
        <li className='btn btn-warning navbtnhpg'>මුල් පිටුවට</li>
      </Link>
      <div className="row">
        <div className="col-12 text-center mb-5 mt-5">
          <h1 className='text-warning'>දන් සීට්ටුව</h1>
          <h3 className='text-light fs-6'>ශ්‍රී විජයවික්‍රමාරාම විහාරස්ථානයේ  ස්වාමීන්වහන්සේලාගෙ හීල් දාන හා දවල් දාන  වල විස්තරයක් පහත දක්වා ඇත.</h3>
        </div>
      </div>
      <div className=" row bg-warning mt-4 border-top border-5 border-light border-bottom">
        <h3 className='text-center'>
          <span className='text-dark fs-1'>{monthData}</span>
          <span className='fs-3 text-dark'> මාසය සඳහා</span>
        </h3>
      </div>
      <div className="row mt-5">
        <div className=" col-md-12 col-lg-6">
          <div className="card bg-dark text-light text-center">
            <h1 className='fs-1 text-warning'>හීල් දානය සඳහා</h1>
            {danaLists.HeelDanaList.map((item) => {
              return (
                <div className="row bg-light mt-3 mb-1 ms-1 me-1 rounded p-1" key={item.id}>
                  <h4 className='col-4 text-dark'>{item.Date} දින</h4>
                  <h4 className='col-8 text-dark'>{item.Doner} විසින්</h4>
                </div>
              )
            })}
          </div>
        </div>
        <div className=" col-md-12 col-lg-6">
          <div className="card bg-dark text-light text-center">
            <h1 className='fs-1 text-warning'>දවල් දානය සඳහා</h1>
            {danaLists.DawalDanaList.map((item) => {
              return (
                <div className="row bg-light mt-3 mb-1 ms-1 me-1 rounded p-1" key={item.id}>
                  <h4 className='col-4 text-dark'>{item.Date} දින</h4>
                  <h4 className='col-8 text-dark'>{item.Doner} විසින්</h4>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Events;
