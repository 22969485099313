import React from 'react'
import { Link } from 'react-router-dom';
function ContactUs() {
    const ridirectoWhatsapp = () => {
        window.open('https://wa.me/94758432067', '_blank');
    }
    return (
        <div className="ContactUs container-fluid">
            <div className="headingnametg row text-center  fs-1 bg-dark pb-4 pt-3  ">
                <h1 className='text-center  text-warning col-12'> විහාරස්ථානය සම්බන්ද කරගැනීමට</h1>
            </div>
            <div className="bg-dark mt-3 row p-5 bg-light ">
                <div className=" row col-lg-6 col-12 fs-1 ">

                    <p className='fs-1 col-12 ms-5 text-warning'>දුරකථන අංකය : 033 226 1086</p>
                </div>
                <div className="col-lg-6 ">
                    <button className='btn btn-warning text-light fs-3 align-self-center ms-5 '><a href="https://www.google.com/maps/place/Sri+Vijaya+Wickramaramaya,+Batuwatta+Rd/@7.0573116,79.9589265,17z/data=!4m6!3m5!1s0x3ae2f90de4f1b98d:0x62c8a82e67a518c9!8m2!3d7.0573116!4d79.9589265!16s%2Fg%2F11q94nbqn3" target="_blank" rel="noreferrer"><i class="fa fa-map-signs" aria-hidden="true"></i> Google Map සඳහා පිවිසීමට </a></button>
                </div>
            </div>
            <div className="headingaddressandbuttontg bg-light p-2 row mb-1 mt-2 ">
                <div className="headingaddressandbuttonleft p-5  
                    
                   bg-dark col-md-12 col-lg-6 ">
                    <p className='fs-3 text-warning '>නො 3 ,
                        <br /> ශ්‍රී විජය වික්‍රමාරාමය ,
                        <br /> කැන්දලියද්දපාලුව උතුර,
                        <br />ගනේමුල්ල
                        <br />(බටුවත්ත පාර)
                    </p>
                    <div>
                        <Link to="/" className=''>
                            <li className='btn btn-warning  fs-3 '>මුල් පිටුවට </li>
                        </Link>
                    </div>


                </div>
                <div className="tripdetails col-md-12 col-lg-6 bg-light rounded-4">
                    <h2 className=' fs-2  text-center'>පැමිණෙන මාර්ගය කෙටියෙන්...</h2>
                    <p className='fs-5 '>මේ විහරස්ථානය  ඇත්තේ ,
                        කඩවත ගනේමුල්ල පාරේ, උලුකඩේ හන්දියෙන් බටුවත්ත පාරේ (රාගම පාරේ) මීටර් 500 ක් පමන පැමිණිය විටයි.
                        ඔබට මෙම විහාරස්ථානය ගූගල් මැප් :(google maps) ආධාරයෙන්ද සොයා ගත හැක.ඒවාට අදාල සබැඳි (links) ඉහතින් දක්වා ඇත.<br />
                        ඔබ කඩවත අධිවේගී පිවිසුම හරහා පැමිණෙන්නේ (Kadawatha Hgihway Exit) නම් කඩවත ගනේමුල්ල පාරේ ගනේමුල්ල දෙසට පැමිණ, ගනේමුල්ල කමාන්ඩෝ හමුදා මූලස්ථානය පහුකර  උලුකඩේ  හන්දියට පැමිණ එම ස්ථානයෙන් වම් අත දෙසට හැරී , බටුවත්ත පාරේ මීටර් 500 පමණ එනවිට පාරේ වම් පස විහාරස්ථානය  පිහිටා ඇත.</p>


                </div>
            </div>
            <div className="row socielmediascection bg-dark p-3 pt-4 text-center ">
                <div className="whatsapp col-lg-3 col-md-6">
                    <i class="fab fa-whatsapp fs-1 text-warning"></i>
                    <p className='fs-3 text-light' style={{ cursor: "pointer" }} onClick={ridirectoWhatsapp}>වට්සැප් අංකය </p>
                    <p className='fs-4 text-warning'> 0758432067</p>
                </div>
                <div className="facebookpg col-lg-3 col-md-6 mb-2 ">
                    <i class="fab fa-facebook fs-1 text-warning"></i>
                    <p className='fs-3 text-light'>Facebook පිටුවට</p>
                    <button className='btn btn-warning fs-5'><a href="https://www.facebook.com/srivijayawickramaramaya" target="_blank" rel="noopener noreferrer">පිවිසෙන්න</a></button>
                </div>
                <div className="facebookgrp col-lg-3 col-md-6 mb-1 ">
                    <i class="fa fa-users fs-1 text-warning"></i>
                    <p className='fs-3 text-light'>Facebook සමූහයට</p>
                    <button className='btn btn-warning fs-5'><a href="https://www.facebook.com/groups/srivijayawickramaramaya" target="_blank" rel="noopener noreferrer">පිවිසෙන්න</a></button>
                </div>
                <div className="email col-lg-3 col-md-6">
                    <i class="fa fa-envelope fs-1 text-warning"></i>
                    <p className='fs-3 text-light'>විද්‍යුත් ලිපිනය :</p>
                    <p className='text-warning '>srivijayawickramaramaya@
                        <br />gmail.com</p>

                </div>

            </div>
            <div className="googlemaps row mt-3 mb-3 bg-white">
                <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21761.700471878645!2d79.92727015200674!3d7.046131752645468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2f90de4f1b98d%3A0x62c8a82e67a518c9!2sSri%20Vijaya%20Wickramaramaya!5e0!3m2!1sen!2slk!4v1705860607591!5m2!1sen!2slk"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            <div className="doner row">
                <div className="donername col-12 text-center bg-dark p-3">
                    <small className='text-warning'>මෙම වෙබ් අඩවිය චමින්ද ප්‍රියශාන්ත ප්‍රේමතිලක මහතාගේ අධීක්ෂණය යටතේ තරිඳු ලක්ෂාන් මහතාගේ ශ්‍රම පරිත්‍යාගයෙන් සදා නිමකර 2024/04/23 වන බක් පුර පසලොස්වක පොහෝදින ශ්‍රී විජයවික්‍රමාරාම විහාරස්ථානය වෙත බාර දෙන ලදී.</small>
                    <p className='text-warning'>විමසීම් :: 077-2393439 / 071-8720092 </p>
                    <Link to="/login" className=''>
                        <button className="btn btn-danger">ADMIN</button>
                    </Link>
                </div>
                
            </div>
        </div>
    )
}

export default ContactUs;