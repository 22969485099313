import React, { useState, useEffect } from 'react'
import './App.css'
import { Link } from 'react-router-dom'
import { db } from './firebase';
import { query, onSnapshot, collection } from 'firebase/firestore';
function ImageGallery() {
  const [ImageDataList, setImageDataList] = useState([]);
  useEffect(() => {
    const q = query(collection(db, "ImgDatabaseData"));
    const unsbscribe = onSnapshot(q, (querySnapshot) => {
      const ImageDataListArr = [];
      querySnapshot.forEach((doc) => {
        ImageDataListArr.push({ ...doc.data(), id: doc.id });
      });
      setImageDataList(ImageDataListArr);
    });
    return () => {
      unsbscribe();
    }
  }, []);
  return (
    <div className="ImageGallery container-fluid">
      <Link to="/" className=''>
        <li className='btn btn-warning navbtnhpg '>මුල් පිටුවට</li>
      </Link>
      <div className="row bg-dark pt-2 mb-3">
        <h1 className='fs-1 text-warning text-center'> ඡායාරූප එකතුව </h1>
        <p className='text-light text-center'>ශ්‍රී විජයවික්‍රමාරාම විහාරස්ථානයේ විශේෂ අවස්ථා වලදී ලබගත් ඡායරෑප මෙම පිටුවෙහි පල කෙරේ</p>
      </div>

      {ImageDataList.map((imgData, index) => (
        <div key={index}>
          <h1 className='mb-2 text-center fs-2  text-warning'>{imgData.EventName}</h1>
          <div className="d-flex align-items-center">
            {/* Display images */}
            <div className="row">
              {imgData.ImagesLink.slice(0, 4).map((imageUrl, i) => (
                <div className="col-md-6 col-sm-12 col-xl-3" key={i}>
                  <img
                    src={imageUrl}
                    className="card-img-top image-fluid m-1 rounded"
                    alt={`Imagess ${i}`}
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* Display Google Drive link */}
          <button className="btn btn-warning mb-2">
            <a href={imgData.GoogleDriveLink} target="_blank" rel="noopener noreferrer">
              තවත් නැරඹීමට පිවිසෙන්න
            </a>
          </button>
        </div>
      ))}










    </div>

  )
}

export default ImageGallery