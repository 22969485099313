import React from 'react'

function SpecialEventElement({ specialEvent, deleteSpecialEvent }) {
  return (
    <div className="SpecialEventElement rounded bg-dark m-1 align-middle row">
      <li className='list col-6 text-light col-10 fs-5 '>{specialEvent.Date} දින {specialEvent.Doner} විසින් {specialEvent.EventName} පිංකම {specialEvent.EventDetails}</li>
      <button onClick={() => deleteSpecialEvent(specialEvent.id)} className='btn btn-danger col-2 '> <i className="fa-solid fa-trash"></i></button>

    </div>
  )
}

export default SpecialEventElement;