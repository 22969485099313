import React from 'react';
import { Link } from 'react-router-dom'; // Correct import statement for Link
import NavLinks from './NavLinks';
import fpi from './images/fpi.png';
import './App.css';

function Home() {
    return (
        <div className='Home container-fluid'>

            {/* Wrap both the image and NavLinks inside a Link */}

            <img src={fpi} alt="" className='img-fluid' />
            <NavLinks />

        </div>
    );
}

export default Home;
