import React, { useState, useEffect } from 'react'
import HeelDanaElement from './HeelDanaElement';
import DawalDanaElement from './DawalDanaElement';
import PoyaDayElement from './PoyaDayElement';
import SpecialEventElement from './SpecialEventElement';
import UploadImageElement from './UploadImageElement';
import { db } from './firebase';
import { imageDb } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Link } from 'react-router-dom';
import './App.css'


import { query, onSnapshot, collection, addDoc, doc, deleteDoc, updateDoc } from 'firebase/firestore';


function AdminPage() {
  //list of Heeldana.....
  const [heelDanaList, setHeelDanaList] = useState([]);
  //list of DawalDana.....
  const [DawalDanaList, setDawalDanaList] = useState([]);
  //list of Poyaday
  const [poayaDayList, setPoyaDayList] = useState([]);
  //list of special Event
  const [specialEventList, setSpecialEventList] = useState([]);

  const [HeelDanaInput1, setHeelDanaInput1] = useState('');
  const [HeelDanaInput2, setHeelDanaInput2] = useState('');

  const [DawalDanaInput1, setDawalDanaInput1] = useState('');
  const [DawalDanaInput2, setDawalDanaInput2] = useState('');

  const [PoyaDayInput1, setPoyaDayInput1] = useState('');
  const [PoyaDayInput2, setPoyaDayInput2] = useState('');
  const [PoyaDayInput3, setPoyaDayInput3] = useState('');

  const [SpecialEventInput1, setSpecialEventInput1] = useState('');
  const [SpecialEventInput2, setSpecialEventInput2] = useState('');
  const [SpecialEventInput3, setSpecialEventInput3] = useState('');
  const [SpecialEventInput4, setSpecialEventInput4] = useState('');

  const [month, setMonth] = useState('');
  const [act, setact] = useState(false);

  //////////////////////////////////////////////firebase image storage//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [image4, setImage4] = useState('');


  const [topic, setTopic] = useState('');
  const [topicList, setTopicList] = useState([]);
  const [driveUrl, setDriveUrl] = useState('');

  const uploadImagesfunc = async () => {
    setact(true);
    const images = [image1, image2, image3, image4];
    const imageNames = ['image1', 'image2', 'image3', 'image4'];
    const uploadedUrls = [];

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const imageName = imageNames[i];

      const storageRef = ref(imageDb, `files/${topic}/${imageName}`);
      const uploadTaskSnapshot = await uploadBytes(storageRef, image);
      const downloadUrl = await getDownloadURL(uploadTaskSnapshot.ref);
      uploadedUrls.push(downloadUrl);
    }


    uploadImagesfirestore(uploadedUrls);
  };

  const uploadImagesfirestore = async (urls) => {

    await addDoc(collection(db, "ImgDatabaseData"), {
      EventName: topic,
      ImagesLink: urls,
      GoogleDriveLink: driveUrl
    });
    setact(false);
    alert('ඡායාරූප සාර්ථකව ඇතුලත් කරන ලදි');

    window.location.reload();

  };
  useEffect(() => {
    const q6 = query(collection(db, "ImgDatabaseData"));
    const unsbscribe = onSnapshot(q6, (querySnapshot) => {
      const topicListArr = [];
      querySnapshot.forEach((doc) => {
        topicListArr.push({ ...doc.data(), id: doc.id });
      });
      setTopicList(topicListArr);
    });
    return () => {
      unsbscribe();
    }
  }, []);



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  //create heelDana and add to the firebase
  const createHeelDana = async (e) => {
    e.preventDefault();
    if (HeelDanaInput1 === '' && HeelDanaInput2 === '') {
      alert('දිනය  හෝ දායකත්වය දරන පුද්ගලයාගේ නම හෝ හිස් වී ඇත නැවත උත්සහ කරන්න.');
      return;
    };
    await addDoc(collection(db, "HeelDanaya"), {
      Date: HeelDanaInput1,
      Doner: HeelDanaInput2
    })
    alert('දත්ත සාර්ථකව ඇතුලත් වී ඇත.');
    setHeelDanaInput1('');
    setHeelDanaInput2('');

  };
  //create poyaDay and add to the firebase

  const createPoyaDay = async (e) => {
    e.preventDefault();
    if (PoyaDayInput1 === '' && PoyaDayInput2 === '') {
      alert('දිනය ,පිංකමේ නම  හෝ දායකත්වය දරන පුද්ගලයාගේ නම හෝ හිස් වී ඇත නැවත උත්සහ කරන්න.');
      return;
    };
    await addDoc(collection(db, "PoyaDay"), {
      Date: PoyaDayInput1,
      EventName: PoyaDayInput2,
      Doner: PoyaDayInput3
    })
    alert('දත්ත සාර්ථකව ඇතුලත් වී ඇත.');
    setPoyaDayInput1('');
    setPoyaDayInput2('');
    setPoyaDayInput3('');

  };
  //create specialevent and add to the firebase
  const createSpecialEvent = async (e) => {
    e.preventDefault();
    if (SpecialEventInput1 === '' && SpecialEventInput2 === '') {
      alert('දිනය ,පිංකමේ නම  හෝ දායකත්වය දරන පුද්ගලයාගේ නම හෝ හිස් වී ඇත නැවත උත්සහ කරන්න.');
      return;
    };
    await addDoc(collection(db, "SpecialEvents"), {
      Date: SpecialEventInput1,
      EventName: SpecialEventInput2,
      Doner: SpecialEventInput3,
      EventDetails: SpecialEventInput4
    })
    alert('දත්ත සාර්ථකව ඇතුලත් වී ඇත.');
    setSpecialEventInput1('');
    setSpecialEventInput2('');
    setSpecialEventInput3('');
    setSpecialEventInput4('');
  };



  //create dawalDana and add to the firebase
  const createDawalDana = async (e) => {
    e.preventDefault();
    if (DawalDanaInput1 === '' && DawalDanaInput2 === '') {
      alert('දිනය  හෝ දායකත්වය දරන පුද්ගලයාගේ නම හෝ හිස් වී ඇත නැවත උත්සහ කරන්න.');
      return;
    };
    await addDoc(collection(db, "DawalDanaya"), {
      Date: DawalDanaInput1,
      Doner: DawalDanaInput2
    })
    alert('දත්ත සාර්ථකව ඇතුලත් වී ඇත.');
    setDawalDanaInput1('');
    setDawalDanaInput2('');

  };
  //create month and add to the firebase
  const createMonth = async (e) => {
    e.preventDefault();
    if (month === '') {
      alert('මාසය හෝ හිස් වී ඇත නැවත උත්සහ කරන්න.');
      return;
    }
    const monthData = doc(db, 'Month', 'Month');
    await updateDoc(monthData, {
      MonthField: month
    });
    alert('මාසය සාර්ථකව ඇතුලත් වී ඇත.')
    setMonth('');

  };

  //get the HellDana data from the firebase
  useEffect(() => {
    const q1 = query(collection(db, "HeelDanaya"));
    const unsbscribe = onSnapshot(q1, (querySnapshot) => {
      const HeelDanaArr = [];
      querySnapshot.forEach((doc) => {
        HeelDanaArr.push({ ...doc.data(), id: doc.id });
      });
      setHeelDanaList(HeelDanaArr);
    });
    return () => {
      unsbscribe();
    }
  }, []);
  //get the DawalDana data from the firebase
  useEffect(() => {
    const q2 = query(collection(db, "DawalDanaya"));
    const unsbscribe = onSnapshot(q2, (querySnapshot) => {
      const DawalDanaArr = [];
      querySnapshot.forEach((doc) => {
        DawalDanaArr.push({ ...doc.data(), id: doc.id });
      });
      setDawalDanaList(DawalDanaArr);
    });
    return () => {
      unsbscribe();
    }
  }, []);
  //get the PoyaDay data from the firebase
  useEffect(() => {
    const q3 = query(collection(db, "PoyaDay"));
    const unsbscribe = onSnapshot(q3, (querySnapshot) => {
      const PoyaDayArr = [];
      querySnapshot.forEach((doc) => {
        PoyaDayArr.push({ ...doc.data(), id: doc.id });
      });
      setPoyaDayList(PoyaDayArr);
    });
    return () => {
      unsbscribe();
    }
  }, []);
  //get the SpecialEvenet data from the firebase
  useEffect(() => {
    const q4 = query(collection(db, "SpecialEvents"));
    const unsbscribe = onSnapshot(q4, (querySnapshot) => {
      const SpecialEventArr = [];
      querySnapshot.forEach((doc) => {
        SpecialEventArr.push({ ...doc.data(), id: doc.id });
      });
      setSpecialEventList(SpecialEventArr);
    });
    return () => {
      unsbscribe();
    }
  }, []);





  //delete the heelDana from the firebase
  const deleteHeelDana = async (id) => {
    await deleteDoc(doc(db, "HeelDanaya", id));
  }
  //delete the dawalDana from the firebase
  const deleteDawalDana = async (id) => {
    await deleteDoc(doc(db, "DawalDanaya", id));
  }
  //delete the poyaDay from the firebase
  const deletePoyaDay = async (id) => {
    await deleteDoc(doc(db, "PoyaDay", id));
  }
  //delete the specialEvent from the firebase
  const deleteSpecialEvent = async (id) => {
    await deleteDoc(doc(db, "SpecialEvents", id));
  }
  //delete the topic from the firebase
  const deleteUploadImage = async (id) => {
    await deleteDoc(doc(db, "ImgDatabaseData", id));
  }



  return (
    <div className="AdminPage container-fluid bg-dark">

      <Link to="/" className=''>
        <li className='btn btn-warning navbtnhpg'>මුල් පිටුවට</li>
      </Link>
      <div class="row">
        <div class="col-md-6 mt-4">
          <form onSubmit={createMonth}>
            <div class="input-group mb-3 row">
              <input value={month}
                onChange={(e) => setMonth(e.target.value)} type="text" className="form-control me-3 mb-3 ms-3" placeholder="අදාල මාසය ඇතුලත් කරන්න" />

              <div className="input-group-append">
                <h1 className='text-light'>{month}</h1>
                <button type="submit" className=" ms-3 btn btn-primary">තහවුරු කරන්න</button>

              </div>

            </div>

          </form>
        </div>

      </div>
      <div className="row ">
        <div className="breackfast col-sm-12 col-md-6 col-lg-3 bg-warning ">

          <h2 className='m-3'>හීල්දානය සඳහා දායකත්වය</h2>
          <form onSubmit={createHeelDana} action="">
            <input value={HeelDanaInput1} onChange={(e) => setHeelDanaInput1(e.target.value)} type="text" className='form-control m-2' placeholder='දිනය (අංකය පමණක් ඇතුලත් කරන්න)' />
            <input value={HeelDanaInput2} onChange={(e) => setHeelDanaInput2(e.target.value)} type="text" className='form-control m-2' name="" placeholder='දායකත්වය දරන පුත්ගලයගෙ නම ' id="" />
            <button className="btn btn-secondary m-2 text-light fs-5">තහවුරු කරන්න</button>
          </form>
          <ul className='list-unstyled'>
            {heelDanaList.map((heelDana, index) => (
              <HeelDanaElement key={index} heelDana={heelDana} deleteHeelDana={deleteHeelDana} />
            ))
            }
          </ul>
        </div>
        <div className="lunch col-sm-12 bg-warning col-md-6 col-lg-3">
          <h2 className='m-3'>දහවල්දානය සඳහා දායකත්වය</h2>
          <form onSubmit={createDawalDana} action="">
            <input value={DawalDanaInput1} onChange={(e) => setDawalDanaInput1(e.target.value)} type="text" className='form-control m-2' placeholder='දිනය (අංකය පමණක් ඇතුලත් කරන්න)' />
            <input value={DawalDanaInput2} onChange={(e) => setDawalDanaInput2(e.target.value)} type="text" className='form-control m-2' name="" placeholder='දායකත්වය දරන පුත්ගලයගෙ නම ' id="" />
            <button className="btn btn-secondary m-2 text-light fs-5">තහවුරු කරන්න</button>
          </form>
          <ul className='list-unstyled'>
            {DawalDanaList.map((dawalDana, index) => (
              <DawalDanaElement key={index} dawalDana={dawalDana} deleteDawalDana={deleteDawalDana} />
            ))
            }
          </ul>
        </div>
        <div className="lunch col-sm-12 bg-warning col-md-6 col-lg-3">
          <h2 className='m-3'>පොහෝදින පිංකම් සඳහා දායකත්වය</h2>
          <form onSubmit={createPoyaDay} action="">
            <input value={PoyaDayInput1} onChange={(e) => setPoyaDayInput1(e.target.value)} type="text" className='form-control m-2' placeholder='පොහොය ඇතුලත් කරන්න' />
            <input value={PoyaDayInput2} onChange={(e) => setPoyaDayInput2(e.target.value)} type="text" className='form-control m-2' name="" placeholder='පිංකමේ නම ' id="" />
            <input value={PoyaDayInput3} onChange={(e) => setPoyaDayInput3(e.target.value)} type="text" className='form-control m-2' name="" placeholder='දායකත්වය දරන පුත්ගලයගෙ නම ' id="" />
            <button className="btn btn-secondary m-2 text-light fs-5">තහවුරු කරන්න</button>
          </form>
          <ul className='list-unstyled'>
            {poayaDayList.map((poyaday, index) => (
              <PoyaDayElement key={index} poyaday={poyaday} deletePoyaDay={deletePoyaDay} />
            ))
            }
          </ul>
        </div>
        <div className="lunch col-sm-12 bg-warning col-md-6 col-lg-3">
          <h2 className='m-3'>සුවිශේෂී පිංකම් සඳහා දායකත්වය</h2>
          <form onSubmit={createSpecialEvent} action="">
            <input value={SpecialEventInput1} onChange={(e) => setSpecialEventInput1(e.target.value)} type="text" className='form-control m-2' placeholder='දිනය (අංකය පමණක් ඇතුලත් කරන්න)' />
            <input value={SpecialEventInput2} onChange={(e) => setSpecialEventInput2(e.target.value)} type="text" className='form-control m-2' name="" placeholder='පිංකමේ නම ' id="" />
            <input value={SpecialEventInput3} onChange={(e) => setSpecialEventInput3(e.target.value)} type="text" className='form-control m-2' name="" placeholder='දායකත්වය දරන පුත්ගලයගෙ නම ' id="" />
            <input value={SpecialEventInput4} onChange={(e) => setSpecialEventInput4(e.target.value)} type="text" className='form-control m-2' name="" placeholder='පිංකම් සඳහා විස්තර' id="" />

            <button className="btn btn-secondary m-2 text-light fs-5">තහවුරු කරන්න</button>
          </form>
          <ul className='list-unstyled'>
            {specialEventList.map((specialEvent, index) => (
              <SpecialEventElement key={index} specialEvent={specialEvent} deleteSpecialEvent={deleteSpecialEvent} />
            ))
            }
          </ul>
        </div>
        <div className="row ">

          <h1 className='text-light text-center mt-4 mb-4'>ඡයාරෑප ඇතුලත් කිරිමට</h1>
          <p className=' p-2 text-center text-danger rounded '>ඡායාරූප ඇල්බමයක් සකස් කිරිමේදී ප්‍රථමව ගැලපෙන ඡායාරෑප 4 ක් ඇතුලත් කර අනෙක් සියලු ඡායාරෑප <span className='rounded p-1 bg-warning ' >google drive</span> තුලට දමා එයට අදාල සබැඳිය පතින් ඇතුලත් කර <span className='rounded p-1 bg-warning'>තහවුරු කිරිම</span> බොත්තම ඔබන්න. සෑමවිටම ඡායාරූප හතරක්ම එකතු කිරීමට වග බලා ගන්න</p>

        </div>
        <div className="row bg-dark">
          <div className="col-6">
            <p className='text-light ms-4'>පලමු ඡායාරූපය ඇතුලත් කරන්න</p>
            <input onChange={(e) => setImage1(e.target.files[0])} className='fileuploadInput form-control mb-3' type="file" name="" id="" placeholder='පින්තූරය-1 ඇතුලත් කරන්න' />
            <p className='text-light ms-4'>දෙවන  ඡායාරූපය ඇතුලත් කරන්න</p>
            <input onChange={(e) => setImage2(e.target.files[0])} className='fileuploadInput form-control mb-3' type="file" name="" id="" placeholder='පින්තූරය-2 ඇතුලත් කරන්න' />
            <p className='text-light ms-4'>තුන්වන ඡායාරූපය ඇතුලත් කරන්න</p>
            <input onChange={(e) => setImage3(e.target.files[0])} className='fileuploadInput form-control mb-3' type="file" name="" id="" placeholder='පින්තූරය-2 ඇතුලත් කරන්න' />
            <p className='text-light ms-4'>හතරවන ඡායාරූපය ඇතුලත් කරන්න</p>
            <input onChange={(e) => setImage4(e.target.files[0])} className='fileuploadInput form-control mb-3' type="file" name="" id="" placeholder='පින්තූරය-2 ඇතුලත් කරන්න' />
          </div>
          <div className=" col-6  d-flex flex-column align-items-center " >
            <p className='text-white'>සුදුසු නමක් ඇතුලත් කරන්න</p>
            <input type="text" name="" onChange={(e) => setTopic(e.target.value)} className="form-control mb-3" placeholder="අදාල අවස්ථාව ඇතුලත් කරන්න" id="" />
            <p className='text-white'> Google Drive සබැඳිය සඳහා</p>
            <input onChange={(e) => setDriveUrl(e.target.value)} type="text" className="form-control mb-3" name="" placeholder=" Google Drive සබැඳිය ඇතුලත් කරන්න" id="" />
            <input type="button" onClick={uploadImagesfunc} className='btn btn-primary mb-3' value="තහවුරු කරන්න" />
            <div className={`${act ? 'activated' : 'deactivated'} loader`}></div>
          </div>

        </div>
        <div className="row">
          <div className="col-12">
            <h2 className='text-light text-center mt-4 mb-3'>ඇතුලත් කර ඇති ඡායාරූප ඇල්බම්</h2>
            <ul className='list-unstyled'>
              {topicList.map((topic, index) => (
                <UploadImageElement key={index} topic={topic} deleteUploadImage={deleteUploadImage} />
              ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>




  )
}

export default AdminPage; 