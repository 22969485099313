import React from 'react'

function HeelDanaElement({ heelDana, deleteHeelDana }) {
  return (
    <div className="HeelDanaElement rounded bg-dark m-1 align-middle row">
      <li className='list col-6 text-light col-10 fs-5 '>{heelDana.Date} දින {heelDana.Doner} විසින්</li>
      <button onClick={() => deleteHeelDana(heelDana.id)} className='btn btn-danger col-2 '> <i className="fa-solid fa-trash"></i></button>

    </div>
  )
}

export default HeelDanaElement;