import React from 'react'
import lkHmd from './images/lkhmd.webp';
import smlhmd from './images/smlhmd.jpg';
import sundayscl1 from './images/sundayscl1.jpg';
import sundayscl2 from './images/sundayscl2.jpg';
import sundayscl3 from './images/sundayscl3.jpg';
import sundayscl4 from './images/sundayscl4.jpg';
import sundayscl5 from './images/sundayscl5.jpg';
import sundayscl6 from './images/sundayscl6.jpg';
import sundayscl7 from './images/sundayscl7.jpg';
import sundayscl8 from './images/sundayscl8.jpg';
import sundayscl9 from './images/sundayscl9.jpg';
import { Link } from 'react-router-dom';
function AboutUs() {
    return (
        <div className="AboutUs container-fluid ">

            <div className="tmp-dtails ">
                <div className='row  pt-4 pb-2 bg-dark mb-2'>
                    <Link to="/" className=''>
                        <li className='btn btn-warning navbtnhpg'>මුල් පිටුවට</li>
                    </Link>
                    <h1 className='tmp-dtailsh1  text-center fs-1 text-warning '>විහාරස්ථානය හා දහම් පාසල පිලිබඳ</h1>
                </div>
                <p className='tmp-dtailsp row fs-6 p-3 text-light bg-dark'>
                    &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
                    කැන්දලියද්දපාළුව ශ්‍රි විජය වික්‍රමාරාම විහාරය ( ගණේමුල්ල ප්‍රදේශයේ ඉදිවන අලුත් පංසල )

                    2018 වර්ෂයේ මුල් භාගයේදී කැන්දලියද්දපාළුව ග්‍රාමයේ පදිංචි ව සිටිඑල්. ඒ. ඩී. වික්‍රමරත්න මහතා  සහ පවුලේ පිරිස විසින්, සම්බුද්ධ ශාසනයේ චිර පැවැත්ම උදෙසා රම්මුතුගල ශ්‍රී විජයාරාම විහාරාධිපති, ශාස්ත්‍රපති පොතුවිල චන්ද්‍රසිරි  නායක සමිදුන් ප්‍රමුඛ මහා සංඝරත්නයට පර්චස් පහනහකට ආසන්න ගනේමුල්ල, කන්දලියද්ද පාළුවේ පිහිටි මෙම පුණ්‍ය භූමිය පූජා කරන ලදී.
                    2018 නොම්බර මස 20 වන දින එම පිංබිම තුළ තාවකාලිකව ඉදිකරන ලද සංඝාවාසය තුළ මහා සංඝරත්නය වැඩ වැසීම ආරම්භ විය. නව විහාරස්තානයක් ඉදිකිරීමෙදී  පැන නැගෙන බොහෝ බාධක කම්කටොලු මැද, ඒ සියලු අභියෝග මැදහත් සිතින් ජයගනිමින් උපසම්පදා සම්පන්න භික්ෂූන් වහන්සේලා ප්‍රමුඛ පස්නමකට ආසන්න භික්ෂු  පිරිසක්, ප්‍රදේශයේ ජනතාවගේ සියලු ආගමික අවශ්‍යතාවයන් සම්පූරමින් කරමින් වර්තමානය තෙක් මෙහි වැඩ වාසය කරති.

                    මෙම විහාරස්ථානය ගොඩ නැගුනු මොහොතේ සිට මේ දක්වාම, මෙම ගම් පියසේ වෙසෙන බොහෝ දෙනෙකු ඉතා උනන්දුවෙන් මෙම විහාරස්තානය සමග එකමුතුව කටයුතු කරන අතර, මෙහි බෝධි අංකුරය රෝපණය කිරීම 2019 වසරේදී සිදු කරන ලදී.<br />
                    &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;         එසේම 2019 වසරේ ජනවාරි මාසයේ සිටම පොහොය ශිල සමාදන් වීම ඇරඹුණු අතර, ඒ සඳහා බොහෝ පිරිසක් සහභාගි වන ලදී. එතැන් සිට මේ දක්වා සෑම මසකම පොහොය දින එම පෙහෙවස් සමාදන්වීම් සිදු කෙරෙමින් පවතී. එසේම 2019 වසරේ සිට මේ දක්වාම, සෑම වසරකම වාර්ෂික කටින පුජෝත්සවයද ඉතාමත් අලංකාරව සහ ගෞරවාන්විතව සිදු කර ඇත. ඒ සඳහා ප්‍රදේශයේ බැතිමතුන්ගේ නොමද සහයක් ලැබී ඇත.

                    මෙම පින්කම් වලට අමතරව, බොහෝ මාසවල දහම් දේශනා, පිරිත් පින්කම්, අටවිසි බෝධි පුජා, සත්බුදු වන්දනා, ආශිර්වාද පින්කම්, ලේ දන්දීමේ පින්කම් වැනි තවත් නොයෙක් ආකාරයේ පින්කම් සහ සමාජ සේවා කටයුතු වල නිරතවෙමින් ප්‍රදේශයේ ජනතාවගේ සුභ සිද්ධිය වෙනුවෙන් කටයුතු සිදු කර ඇති අතර, ඉදිරියටත් මෙවැනි කටයුතු පවත්වාගෙන යාම විහාරස්ථානයේ අරමුනයි.
                    මෙම නව විහාරස්තානය පොහෝ දා සීල භාවනා වැඩ සටහන් මෙන්ම ආගමික,සාමාජීය ,සංස්කෘතික යන සියලු  කටයුතු සිදුකරන නව ආගමික  මධ්‍යස්ථානයකි. ප්‍රදේශයේ බැතිමතුන්ගේ භෞතික සංවර්ධනයත් අද්‍යාත්මීය සංවර්ධනයත් සිදු කරන පුණ්‍ය තීර්තයක් ලෙස මෙම විහාරස්තානය, මේ වන විට ගණේමුල්ල ප්‍රදේශය තුළ විරාජමානව පවතී.

                </p>
            </div>
            <div className="ownership row align-middle">

                <div className="itemcard col-lg-6 col-sm-12 ">
                    <h2 className='ownershiph2 pt-5 pb-5 text-center text-decoration-underline col-12'>විහාරාධිපති හිමි</h2>
                    <img src={lkHmd} className=' mx-auto d-block  mb-5 p-5 img-fluid ' alt="nayaka-thero" />
                    <p className='ownershipp text-center fw-bold fs-3'>
                        කැන්දලියද්දපාළුව ශ්‍රී විජයවික්‍රමාරාමය, රම්මුතුගල ශ්‍රී විජයාරාමය යන  උභය විහාරාධිපති,බස්නාහිර පළාතේ උප ප්‍රධාන සංඝනායක,ශාස්ත්‍රපති,ශ්‍රී ඥානාලෝකාභිධාන පූජ්‍ය පොතුවිල චන්ද්‍රසිරි නායක ස්වාමීන් වහන්සේ
                    </p>
                </div>
                <div className="itemcard col-lg-6  col-sm-12">
                    <h2 className='ownershiph2 pt-5 pb-5 text-center text-decoration-underline col-12'>විහාරාධිකාරී හිමි</h2>
                    <img src={smlhmd} className=' mx-auto d-block mb-5 p-5 img-fluid ' alt="nayaka-thero-ii" />
                    <p className='ownershipp text-center fw-bold fs-3'>
                        ශ්‍රී විජයවික්‍රමාරාම විහාරාධිකාරී,ගෞරව ශාස්ත්‍රවේදී පූජ්‍ය පරපාමුල්ලේ සුමනසිරි හිමි
                    </p>
                </div>
            </div>
            <div className="sunday-scl row">
                <h1 className='sundaysclh1 text-center mt-2 pt-4 fs-1 pb-4 text-warning bg-dark'>
                    ශ්‍රී චන්ද්‍රසිරි දහම් පාසල
                </h1>
                <p className='sundaysclp tmp-dtailsp  fs-5 p-5 text-dark'>
                    ප්‍රදේශයේ  දරුවන්ගේ දහම් අද්‍යාපනය සුපෝෂනය කිරීම අරමුණු කරගෙන පවතින සියලු බාධක කම්කටොලු හමුවේ, මෙම විහාරස්ථානයට අනුබද්ධව ශ්‍රී චන්ද්‍රසිරි දහම් පාසල ආරම්භ කෙරුණු අතර, ආරම්භක දරු පිරිස 28 වූ අතර අද වනවිට එම සංඛ්‍යාව 100 ඉක්මවා යාම බෙහෙවින් සතුට දනවන කරුණකි.

                    පහසුකම් අඩු කුඩා ඉඩකඩක් වුවද, කළමනාකරණය කරගනිමින් මෙම දහම් පාසල පවත්වාගෙන යන අතර, ඉරිදා දිනවල දරු දැරියන් තුල දහම් පාසලට පැමිණීමට ඇති උනන්දුව, උවමනාව දුටුවිට අපහසුතා මධ්‍යයේ වුවද මෙවැනි දහම් පාසලක් ඇරඹීමට හැකිවීම විහාරස්ථානය ලැබූ ජයග්‍රහණයක් බව මෙහිලා ගෞරවයෙන් සටහන් කල යුතුය. ඉදිරි කාලයේදී, මෙම දහම් පාසල තව තවත් දියුණු වී, වැඩි පිරිසකට දහම් අධ්‍යාපනය ලබා දෙන මධ්‍යස්ථානයක් බවට පත්වනු දැකීම අප කාගේත් පැතුම බවද සිහිපත් කල යුතුය. එසේම දරුවන්ගේ ආගමික නැඹුරුව වර්ධනය කර ගැනීමටත්, ඔවුන් තුල යහපත් ආකල්ප වර්ධනය කොට යහපත් ගුණවත්, සමාජයට වැඩදායි පුද්ගලයින් පිරිසක් මෙම දහම් පාසල තුලින් බිහිකරගැනීම විහාරස්ථානයේ පරම අපේක්ෂාවයි.

                </p>
                <div className="sundaysclimgcontainer bg-dark row">
                    <div className="col-sm-12 col-md-6 mt-4 col-lg-4">
                        <img src={sundayscl1} className='sundaysclimg img-fluid mx-auto mb-3 d-block  ' alt="sundayscl1" />
                    </div>
                    <div className="col-sm-12 col-md-6 mt-4 col-lg-4">
                        <img src={sundayscl2} className='sundaysclimg img-fluid mx-auto mb-3 d-block  ' alt="sundayscl1" />
                    </div>
                    <div className="col-sm-12 col-md-6 mt-4 col-lg-4">
                        <img src={sundayscl3} className='sundaysclimg img-fluid mx-auto mb-3 d-block  ' alt="sundayscl1" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <img src={sundayscl4} className='sundaysclimg img-fluid mx-auto mb-3 d-block  ' alt="sundayscl1" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <img src={sundayscl5} className='sundaysclimg img-fluid mx-auto mb-3 d-block  ' alt="sundayscl1" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <img src={sundayscl6} className='sundaysclimg img-fluid mx-auto mb-3 d-block  ' alt="sundayscl1" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <img src={sundayscl7} className='sundaysclimg img-fluid mx-auto mb-3 d-block  ' alt="sundayscl1" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <img src={sundayscl8} className='sundaysclimg img-fluid mx-auto mb-3 d-block  ' alt="sundayscl1" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <img src={sundayscl9} className='sundaysclimg img-fluid mx-auto mb-3 d-block  ' alt="sundayscl1" />
                    </div>

                </div>

            </div>

        </div>
    )
}

export default AboutUs;