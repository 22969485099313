
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
const firebaseConfig = {
    apiKey: "AIzaSyAVjygqwwy1Z-aTGrlX58Fkp6KhEDdbiuk",
    authDomain: "temple-cd2c5.firebaseapp.com",
    projectId: "temple-cd2c5",
    storageBucket: "temple-cd2c5.appspot.com",
    messagingSenderId: "1033291252061",
    appId: "1:1033291252061:web:4cd43c792cca329b10e8b4",
    measurementId: "G-5WL3NDBLVR"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const imageDb = getStorage(app);

