import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from './AuthContext'
import { Link } from 'react-router-dom';
function Loginpg() {
  const { signIn } = UserAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signIn(email, password);
      navigate('/admin');
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        alert(' ඇතුලත් කල දත්ත නොගැලපේ.');
      } else if (error.code === 'auth/wrong-password') {
        alert(' ඇතුලත් කල මුරපදය වැරදී.');
      } else if (error.code === 'auth/invalid-email') {
        alert('ඇතුලත් කල විද්‍යුත් ලිපිනය වැරදී.');
      } else {
        alert('අන්තර්ජාල  සම්බන්දතාවයේ යම් දෝශයක් හෝ වෙනත් බිඳ වැටීමක් සිදුවී ඇත්. කරුණාකර  මදවෙලාවකට පසු උත්සහ කරන්න');
      }
    }
    setEmail('');
    setPassword('');
  }

  return (
    <div className="Login bg-dark d-flex justify-content-center align-items-center vh-100">
      <form onSubmit={handleSubmit} className="container col-md-4" >
        <div className="form-group">
          <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control mb-3" id="email" placeholder="ඊමේල් ලිපිනය ඇතුලත් කරන්න" autoComplete="off" />
          <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control mb-3" id="password" placeholder=" මුරපදය ඇතුලත් කරන්න" autoComplete="off" />
          <button type="submit" className="btn btn-outline-warning">තහවුරු කරන්න</button>
          <Link to="/" className=''>
            <li className='btn btn-outline-warning ms-5'>මුල් පිටුවට </li>
          </Link>
        </div>

      </form>
    </div>
  );
}

export default Loginpg;
