import React from 'react'

function UploadImageElement({topic,deleteUploadImage}) {
  return (
    <div className="UploadImageElement rounded bg-dark m-1 align-middle row ">
            <li className='list col-6 text-light col-9 fs-5 '>{topic.EventName} </li>
            <button onClick={() => deleteUploadImage(topic.id)} className='btn btn-danger col-2 '> <i className="fa-solid fa-trash"></i></button>

        </div>
  )
}

export default UploadImageElement;