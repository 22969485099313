import React from 'react'
import { Link } from 'react-router-dom'
function NavLinks() {
    return (
        <div className="NavLinks row">
            <ul className='btn-group  navbtnul'>
                <Link to="/about">
                    <li className='btn btn-warning ms-3 fs-5 navbtn'>විහාරස්ථානය පිලිබඳ</li>
                </Link>
                <Link to="/events">
                    <li className='btn btn-warning ms-3 fs-5 navbtn'>ආගමික කටයුතු </li>
                </Link>
                <Link to="/notice">
                    <li className='btn btn-warning ms-3 fs-5 navbtn'>විශේෂ දැනුම්දීම්</li>
                </Link>
                <Link to="/gallery">
                    <li className='btn btn-warning ms-3 fs-5 navbtn'>ඡායාරූප එකතුව</li>
                </Link>
                <Link to="/contactus">
                    <li className='btn btn-warning ms-3 fs-5 navbtn'>සම්බන්ද කරගැනීමට</li>
                </Link>

            </ul>
        </div>
    )
}

export default NavLinks;