import React from 'react'

function DawalDanaElement({ dawalDana, deleteDawalDana }) {
    return (
        <div className="DawalDanaElement rounded bg-dark m-1 align-middle row">
            <li className='list col-6 text-light col-10 fs-5 '>{dawalDana.Date} දින {dawalDana.Doner} විසින්</li>
            <button onClick={() => deleteDawalDana(dawalDana.id)} className='btn btn-danger col-2 '> <i className="fa-solid fa-trash"></i></button>

        </div>
    )
}

export default DawalDanaElement; 