import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { Link } from 'react-router-dom';
import './App.css';

import { query, onSnapshot, collection } from 'firebase/firestore';

function NoticeBoard() {
    const [monthData, setMonthData] = useState('');

    // State for PoyaDay and SpecialEvent lists
    const [poyaDayList, setPoyaDayList] = useState([]);
    const [specialEventList, setSpecialEventList] = useState([]);

    useEffect(() => {
        // Fetch PoyaDay data
        const poyaDayQuery = query(collection(db, "PoyaDay"));
        const poyaDayUnsubscribe = onSnapshot(poyaDayQuery, (querySnapshot) => {
            const poyaDayArr = [];
            querySnapshot.forEach((doc) => {
                poyaDayArr.push({ ...doc.data(), id: doc.id });
            });
            setPoyaDayList(poyaDayArr);
        });

        // Fetch SpecialEvents data
        const specialEventQuery = query(collection(db, "SpecialEvents"));
        const specialEventUnsubscribe = onSnapshot(specialEventQuery, (querySnapshot) => {
            const specialEventArr = [];
            querySnapshot.forEach((doc) => {
                specialEventArr.push({ ...doc.data(), id: doc.id });
            });
            setSpecialEventList(specialEventArr);
        });

        return () => {
            poyaDayUnsubscribe();
            specialEventUnsubscribe();
        };
    }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'Month'), (snapshot) => {
            snapshot.forEach((doc) => {
                setMonthData(doc.data().MonthField);
            });
        });

        return () => unsubscribe();
    }, []);

    return (
        <div className="NoticeBoard bg-dark container-fluid">
            <Link to="/" className=''>
                <li className='btn btn-warning navbtnhpg '>මුල් පිටුවට</li>
            </Link>
            <div className="row">
                <div className="col-12 text-center mb-5 mt-5">
                    <h1 className='text-warning mb-3'>විශේෂ දැනුම්දීම් </h1>
                    <h3 className='text-light fs-6'>ශ්‍රී විජයවික්‍රමාරාම විහාරස්ථානයේ දායක සභා රැස්වීම් සුවිශේෂ පිංකම් , පොහෝදින පිංකම් , විශේෂ ධර්ම දේශනා, කඨින පිංකම් ආදියෙහි තොරතුර මෙම පිටුවෙහි පල වේ. </h3>
                </div>
            </div>
            <div className=" row bg-warning mt-4 border-top border-5 border-light border-bottom">
                <h3 className='text-center'>
                    <span className='text-dark fs-1 '>{monthData}</span>
                    <span className='fs-3 text-dark'> මාසය සඳහා</span>
                </h3>
            </div>
            <div className="row  ">
                <h2 className='text-warning text-center mt-5'>පොහෝදින පිංකම් පිලිබඳව</h2>
                {
                    poyaDayList.map((poyaDay) => (
                        <div key={poyaDay.id} className="NoticeBoardElement col-lg-3 col-md-4 col-sm-12 col-12 rounded">
                            <div className="card text-bg-light mt-5 ms-2 me-2" style={{ maxWidth: '18em' }}>
                                <div className="card-header"><h2 className='text-dark'>{poyaDay.EventName}</h2></div>
                                <div className="card-body">
                                    <h4>{poyaDay.Date}පොහෝ දින</h4>

                                    <p className="card-text">{poyaDay.Doner} විසින් දායකත්වය ගෙන කටයුතු කරයී</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="row">
                <h2 className='text-warning text-center mt-5'>සුවිශේෂී පිංකම් පිලිබඳව</h2>
                {specialEventList.map((specialEvent) => (
                    <div key={specialEvent.id} className="NoticeBoardElement col-lg-3 col-md-4 col-sm-12 col-12 rounded">
                        <div className="card text-bg-light mt-5 ms-2 me-2" style={{ maxWidth: '18em' }}>
                            <div className="card-header"><h2 className='text-dark'>{specialEvent.EventName}</h2></div>
                            <div className="card-body">
                                <h4>{specialEvent.Date} දින</h4>
                                <h5 className="card-title">{specialEvent.EventDetails}</h5>
                                <p className="card-text">{specialEvent.Doner} විසින් දායකත්වය ගෙන කටයුතු කරයී</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NoticeBoard;
